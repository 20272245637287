import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyD8Q_FAMxN7Crpp4eItDOErzCZpsowhGJ4",
  authDomain: "timesheet-lock-poc.firebaseapp.com",
  projectId: "timesheet-lock-poc",
  storageBucket: "timesheet-lock-poc.appspot.com",
  messagingSenderId: "102678186872",
  appId: "1:102678186872:web:997b02127ec24e931c168d",
};

firebase.initializeApp(firebaseConfig);

export default firebase;
