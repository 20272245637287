import { useEffect, useState } from "react";
import {
  getAuth,
  onAuthStateChanged,
  User as FirebaseAuthUser,
} from "firebase/auth";
import { Navigate } from "react-router-dom";

export function useAuth(): FirebaseAuthUser | null {
  const [user, setUser] = useState<FirebaseAuthUser | null>(null);
  const auth = getAuth(); // Get the auth instance

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user as FirebaseAuthUser);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  return user;
}
