import React, { useState, useEffect } from "react";
import firebase from "../firebaseConfig";
import { CircularProgress } from "@mui/material";
import NavBar from "../components/NavBar";
import { LogData, GroupedLog } from "../types";
import DataTable from "../components/DataTable";
import { Container } from "@mui/material";
import "./Devices.css";
const Logs = () => {
  const [loading, setLoading] = useState(true);
  const [logs, setLogs] = useState<GroupedLog[]>([]);

  const fetchLogs = async () => {
    const db = firebase.firestore();
    const logsSnapshot = await db.collection("logs")
    .orderBy("date_time", "desc")
    .get();
    const groupedLogs: GroupedLog[] = [];

    logsSnapshot.forEach((doc) => {
      const logData = doc.data() as LogData;
      if(logData.action != "EMAIL")
      {

        const date = logData.date_time.toDate().toISOString().split("T")[0];
  
        // Find the index of the date in the array
        const existingIndex = groupedLogs.findIndex(
          (groupedLog) => groupedLog.id === date
        );
  
        if (logData.lock_status == "200" && logData.action === "LOCK") {
          if (existingIndex === -1) {
            groupedLogs.push({
              id: date,
              lockCount: 1,
              logs: [logData],
            });
          } else {
            groupedLogs[existingIndex].lockCount++;
            groupedLogs[existingIndex].logs.push(logData);
          }
        } else if (existingIndex === -1) {
          // Create an entry with zero locks if not existing
          groupedLogs.push({
            id: date,
            lockCount: 0,
            logs: [],
          });
        }
      }
    });
    setLoading(false);
    if (groupedLogs.length > 2) {
      const slicedLogs = groupedLogs.slice(0, 100); 
      setLogs(slicedLogs);
    } else {
      setLogs(groupedLogs);
    }
  };

  useEffect(() => {
    fetchLogs();
  }, []);

  return (
    <div className="pageWrapper">
      <NavBar />
      <Container className="mui-custom-container " >
        <div></div>
        <div className="dataWrapperLog innerWrapper">
          <h2>Logs</h2>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <DataTable
              row={logs}
              columns={[
                { field: "id", headerName: "Date", width: 250 },
                {
                  field: "lockCount",
                  headerName: "Locked Devices",
                  width: 150,
                },
              ]}
            />
          )}
        </div>
      </Container>
    </div>
  );
};

export default Logs;
