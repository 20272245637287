import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import { useAuth } from "./hooks/useAuth"; // Import the custom hook
import WhiteList from "./pages/WhiteList";
import Devices from "./pages/Devices";
import Logs from "./pages/Logs";
import { useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";

const App: React.FC = () => {
  const user = useAuth();
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setEnabled(true);
    }, 2000);
  }, []);

  if (!enabled) {
    return (
      <div style={{ height: "calc(100vh - 64px)", width: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </div>
      </div>
    );
  }

  return (
    <Router>
      <div>
        <Routes>
          <Route
            path="/"
            element={user ? <Dashboard user={user} /> : <Login />}
          />
          {/* Regular Route for the dashboard */}
          <Route
            path="/dashboard"
            element={user ? <Dashboard user={user} /> : <Navigate to="/" />}
          />
          <Route
            path="/devices"
            element={user ? <Devices /> : <Navigate to="/" />}
          />
          <Route
            path="/whitelist"
            element={user ? <WhiteList /> : <Navigate to="/" />}
          />
          <Route path="/logs" element={user ? <Logs /> : <Navigate to="/" />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
