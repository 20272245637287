import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import firebase from "../firebaseConfig";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../components/theme"; // Import the custom theme
import CircularProgress from "@mui/material/CircularProgress";
import "./Login.css"; // Import custom styles for Login component
import { DATA } from "../utils/constants/data";

const Login: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false); // Add loading state
  const navigate = useNavigate(); // Use useNavigate hook for redirection

  const handleLogin = async () => {
    setError(null); // Reset the error state before login

    // Validate email format using regular expression

    if (!DATA.regex.emailReg.test(email)) {
      setError("Invalid email format");
      return;
    }

    if (email === "" || password === "") {
      setError("Please enter both email and password");
      return;
    }

    setLoading(true); // Show loader while logging in

    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);
      navigate("/dashboard"); // Redirect to dashboard after successful login
    } catch (error) {
      const authError = error as firebase.auth.Error; // Type assertion here
      setError(authError.message);
    } finally {
      setLoading(false); // Hide loader after login attempt
    }
  };

  return (
    <ThemeProvider theme={theme}>
      {/* Apply the custom theme */}
      <div>
        {/* <NavBar /> */}
        <div className="login-container">
          <h1 className="login-heading">Login</h1>
          <div className="login-form">
            <label>Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <label>Password:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button onClick={handleLogin}>Login</button>
            {loading && (
              <CircularProgress size={24} style={{ marginLeft: "10px" }} />
            )}
            {/* Show loader while logging in */}
            {error && <div className="error-message">{error}</div>}
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default Login;
