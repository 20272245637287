import React from "react";
import { DataGrid } from "@mui/x-data-grid";
// import "./Dashboard.css"

interface DataTableProps {
  row: Array<any>;
  columns: Array<any>;
  customClass?: string;
}
const getRowClassName = () => {
  return "custom-row-class";
};

const DataTable: React.FC<DataTableProps> = ({ row, columns }) => {
  return (
    <DataGrid
      className="abc"
      rows={row}
      columns={columns}
      getRowClassName={getRowClassName}
    />
  );
};


export default DataTable;
