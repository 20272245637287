import React, { useState } from "react";
import { AppBar, Toolbar, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import firebase from "firebase/compat/app";
import { useLocation } from "react-router-dom";
import "../pages/Dashboard.css";
import LogoutIcon from "@mui/icons-material/Logout";

const NavBar: React.FC = () => {
  const theme = useTheme();

  const location = useLocation();
  const route = location.pathname;

  const handleLogout = async () => {
    try {
      await firebase.auth().signOut();
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const navStyle = {
    color: theme.palette.common.white,
    textDecoration: "none",
    marginLeft: theme.spacing(2),
  };

  const navElements = [
    {
      style: navStyle,
      to: "/dashboard",
      title: "Dashboard",
    },
    {
      style: navStyle,
      to: "/devices",
      title: "Devices",
    },
    {
      style: navStyle,
      to: "/whitelist",
      title: "WhiteList",
    },
    {
      style: navStyle,
      to: "/logs",
      title: "Logs",
    },
  ];

  return (
    <AppBar
      position="static"
      style={{ backgroundColor: theme.palette.primary.main }}
    >
      <Toolbar className="navTabs">
        <Typography
          variant="h6"
          component={Link}
          to="/dashboard"
          style={{
            flexGrow: 1,
            textDecoration: "none",
            color: theme.palette.common.white,
            fontSize: "1.5rem",
            fontWeight: "bold",
          }}
        >
          Timesheet
        </Typography>
        {/* add more nav items in navElements Array */}
        {navElements.map((nav) => {
          const isActive = route === nav.to;
          return (
            <Button
              component={Link}
              to={nav.to}
              className={isActive ? "nav-element active" : "nav-element"}
              style={{
                ...nav.style,
                color:
                  route === nav.to
                    ? theme.palette.common.white
                    : nav.style.color,
              }}
              // className="nav-element"
            >
              {nav.title}
            </Button>
          );
        })}
        <Button
          // className="btnPrimary"
          style={{
            ...navStyle,
            backgroundColor: theme.palette.primary.main,
            color: "#fff",
          }}
          onClick={handleLogout}
        >
          <LogoutIcon />
          {/* Log out */}
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
