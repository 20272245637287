import React, { useState, useEffect } from "react";
import firebase from "../firebaseConfig";
import { Checkbox, CircularProgress } from "@mui/material";
import NavBar from "../components/NavBar";
// import Box from "@mui/material/Box";
import { WhiteListInterface } from "../types"; // Import the User type from the types file
// import SearchBar from "../components/Searchbar";
import DataTable from "../components/DataTable";
import { Container } from "@mui/material";
import { useLocation } from "react-router-dom";
import "./Devices.css";

const WhiteList = () => {

  const searchParams = new URLSearchParams(useLocation().search);
  const isWhitelistedRequested = searchParams.get('filter') === 'whitelisted';

  
  const [users, setUsers] = useState<WhiteListInterface[]>([]);
  // const [originalUsers, setOriginalUsers] = useState<WhiteListInterface[]>([]);
  const [loading, setLoading] = useState(true);
  // const [search, setSearch] = useState<string>("");
  const [checkboxDisable, setCheckboxDisable] = useState(false);

  // fetch users from firestore
  const fetchUsers = async () => {
    try {
      const db = firebase.firestore();
      const usersSnapshot = await db.collection("users").get();

      const usersData: WhiteListInterface[] = usersSnapshot.docs.map((doc) => ({
        id: doc.id,
        display_name: doc.data().display_name,
        email: doc.data().email,
        whitelist: doc.data().whitelist,
        whitelist_count: doc.data().whitelist_count,
      }));

      let filteredUsers = usersData;
      if (isWhitelistedRequested) {
        filteredUsers = usersData.filter(user => user.whitelist);
      }

      setUsers(filteredUsers);
      // setOriginalUsers(usersData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching users:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const columns = [
    {
      field: "whitelist",
      headerName: "Whitelist",
      width: 80,
      renderCell: (params: any) => (
        <Checkbox
          disabled={checkboxDisable}
          onChange={() => handleSelectionChange(params.id)}
          checked={params.row?.whitelist ? params.row?.whitelist : false}
        />
      ),
    },
    { field: "display_name", headerName: "Employee Name", width: 200 },
    { field: "id", headerName: "Email", width: 300 },
    { field: "whitelist_count", headerName: "Whitelist Count", width: 150 },
  ];

  const handleSelectionChange = async (selectedId: string) => {
    try {
      setCheckboxDisable(true);
      const updatedUsers = [...users];
      const userToUpdate = updatedUsers.find((user) => user.id === selectedId);
      if (userToUpdate) {
        userToUpdate.whitelist = !userToUpdate.whitelist;
       

        // // update and add whitelist status:
        const db = firebase.firestore();
        await db.collection("users").doc(selectedId.toString()).update({
          whitelist: userToUpdate.whitelist,
          whitelist_count: userToUpdate.whitelist ? (userToUpdate.whitelist_count || 0) + 1 : (userToUpdate.whitelist_count || 0),
        });
     
        if (userToUpdate.whitelist) {
          userToUpdate.whitelist_count = userToUpdate.whitelist_count ? userToUpdate.whitelist_count + 1 : 1;
        }

        setUsers(updatedUsers);
        setCheckboxDisable(false);
      }
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  // // searches and filters the array
  // const handleSearch = () => {
  //   const filteredUsers = users.filter((user) =>
  //     user.display_name.toLowerCase().includes(search.toLowerCase())
  //   );
  //   setUsers(filteredUsers);
  // };

  // const handleClear = () => {
  //   setSearch("");
  //   setUsers(originalUsers);
  // };

  return (
    <div className="pageWrapper">
      <NavBar />
      <Container className="mui-custom-container">
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "16px",
          }}
        ></div>
        <div className="innerWrapper" style={{ height: "calc(100vh - 64px)", width: "100%" }}>
          <h2>Whitelist</h2>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              {/* <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="200px"
            >
              <SearchBar
                label="Search"
                searchValue={search}
                onChange={setSearch}
                onClear={handleClear}
                onSearch={handleSearch}
              />
            </Box> */}

              <DataTable row={users} columns={columns} />
            </>
          )}
        </div>
      </Container>
    </div>
  );
};

export default WhiteList;
