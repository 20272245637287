import React, { useState, useEffect } from "react";
import firebase from "../firebaseConfig";
import { CircularProgress } from "@mui/material";
import NavBar from "../components/NavBar";
import Avatar from "@mui/material/Avatar";
import axios from "axios";
import { User } from "../types"; // Import the User type from the types file
// import Box from "@mui/material/Box";
// import SearchBar from "../components/Searchbar";
import DataTable from "../components/DataTable";
import { Container } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { capitalize } from "../utils/helpers/helpers";
import { DATA } from "../utils/constants/data";
import { useLocation } from "react-router-dom";
import "./Devices.css";
interface Params {
  id: string;
  email: string;
  locked?: boolean;
  created_on: Date;
  updated_on: Date;
  first_name: string;
  last_name: string | null;
  avatar_url: string;
  os_type: string;
  display_name?: string;
}

enum FilterEnum {
  All = 'all',
  Locked = 'locked',
  Unlocked = 'unlocked',
  Whitelisted = 'whitelisted'
}


const Devices = () => {

  const searchParams = new URLSearchParams(useLocation().search);
  const isLockedDevicesRequested = searchParams.get('filter') === FilterEnum.Locked;
  const isUnLockedDevicesRequested = searchParams.get('filter') === FilterEnum.Unlocked;
  
  // const [originalUsers, setOriginalUsers] = useState<User[]>([]);
  const { osType } = DATA;
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [processingId, setProcessingId] = useState<string>("");
  // const [search, setSearch] = useState<string>("");

  const handleLockUnlockUser = async (params: Params) => {
    try {
      const updatedUsers = [...users];
      const userToUpdate = updatedUsers.find((user) => user.id === params.id);
      if (userToUpdate) {
        userToUpdate.locked = !userToUpdate.locked;
        // Update the user's "locked and lock_count" field in Firestore
        const db = firebase.firestore();
        await db.collection("users").doc(params.id.toString()).update({
          locked: userToUpdate.locked,
          lock_count: userToUpdate.lock_count ? userToUpdate.lock_count + 1 : 1,
        });

        if (userToUpdate.locked) {
          userToUpdate.lock_count = userToUpdate.lock_count ? userToUpdate.lock_count + 1 : 1;
        }
        handleScaleFusion(params.id);

        setUsers(updatedUsers);
      }
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  const handleScaleFusion = (id: string) => {
    const url =
      "https://us-central1-timesheet-lock-poc.cloudfunctions.net/lockDeviceAPI";
    axios
      .get(`${url}?email=${id}`)
      .then(() => setProcessingId(""))
      .catch((err) => {
        console.log("err", err);
        setProcessingId("");
      });
  };


  const handleRemoveUser = async (params: Params) => {
    try {
      const updatedUsers = [...users]; // Assuming users is an array of user objects
      const userIndexToRemove = updatedUsers.findIndex((user) => user.id === params.id);
      if (userIndexToRemove !== -1) {
        const db = firebase.firestore();
        await db.collection("users").doc(params.id.toString()).delete(); 
        await db.collection("devices").doc(params.id.toString()).delete(); 
  
        handleScaleFusion(params.id); // Assuming this function handles other operations after user deletion
  
        updatedUsers.splice(userIndexToRemove, 1); // Remove the user from the local array
        setUsers(updatedUsers); // Update the state with the modified user list
      }
    } catch (error) {
      console.error("Error removing user:", error);
      // Handle errors if necessary
    }
  };

  const fetchUsers = async () => {
    try {
      const db = firebase.firestore();
      const usersSnapshot = await db.collection("users").get();

      const usersData: User[] = usersSnapshot.docs.map((doc) => ({
        id: doc.id,
        created_on: new Date(doc.data().created_on), // Convert string to Date
        updated_on: new Date(doc.data().updated_on), // Convert string to Date
        first_name: doc.data().first_name,
        last_name: doc.data().last_name,
        email: doc.data().email,
        avatar_url: doc.data().avatar_url,
        locked: doc.data().locked,
        os_type: doc.data().os_type,
        display_name: doc.data().display_name,
        device_id: doc.data().device_id,
        mac_pin: doc.data().mac_pin,
        lock_count: doc.data().lock_count,
      }));
      // setOriginalUsers(usersData);

      let filteredUsers = usersData;
      if (isLockedDevicesRequested) {
        filteredUsers = usersData.filter(user => user.locked);
      }
      if (isUnLockedDevicesRequested) {
        filteredUsers = usersData.filter(user => !user.locked);
      }
      
      setUsers(filteredUsers);

      
      // setUsers(usersData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching users:", error);
      setLoading(false);
    }
  };

  const handleOStype = (params: any) => {
    if (params.row.os_type === osType.mac && params.row.locked) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

 
  const columns = [
    {
      field: "avatar_url",
      headerName: "",
      width: 70,
      renderCell: (params: any) => (
        <Avatar alt={params.row.first_name} src={params.value as string} />
      ),
    },
    { field: "display_name", headerName: "Employee Name", width: 150 },
    { field: "id", headerName: "Email", width: 270 },
    {
      field: "device_status",
      headerName: "Device Status",
      width: 120,
      renderCell: (params: any) => {
        const status = params.row.locked ? "Locked" : "Unlocked";
        const color = params.row.locked ? "red" : "green";
        const fontWeight = "bold";
    
        return (
          <span style={{ color,fontWeight  }}>
            {status}
          </span>
        );
      },
    },
    // { field: "created_on", headerName: "Created On", width: 200, type: "date" },
    // { field: "updated_on", headerName: "Updated On", width: 200, type: "date" },
    {
      field: "os_type",
      headerName: "OS Type",
      width: 100,
      valueFormatter: (params: any) => {
        const osType = params.value;
        return capitalize(osType);
      },
    },
    {
      field: "locked",
      headerName: "Action",
      width: 180,
      val: 'mac_pin',
      renderCell: (params: any) => {
        const isLocked = params.row.locked;
        return(
          <div className="actionBtn" style={{display: 'flex' , justifyContent: 'space-around' , width: '100%' , alignItems: 'center'}}>
          {processingId === params.id ? (
            <CircularProgress size={15} style={{ marginLeft: "20px" }} />
          ) : params.row.device_id ? (
            <>
            <Tooltip
              placement="right-start"
              title={
                handleOStype(params) ? "Mac cannot be unlocked from here" : ""
              }
            >
              <button
                disabled={isLocked || processingId !== "" || handleOStype(params)}
                style={{
                  backgroundColor: isLocked ? "rgba(128, 128, 128, 0.3)" : "rgba(255, 0, 0, 0.3)",
                  color: isLocked ? "#999" : "#000",
                  border: isLocked ? "1px solid rgba(128, 128, 128, 1)" : "1px solid rgba(255, 0, 0, 1)",
                  padding: "8px 16px",
                  borderRadius: "4px",
                  cursor: "pointer",
                  margin : 0,
                  pointerEvents: !isLocked ? "auto" : "none" // Enable pointer events for locked buttons
                }}
                onClick={() => {
                  setProcessingId(params.id);
                  setTimeout(() => {
                    handleLockUnlockUser(params);
                  }, 500);
                }}
              >
                Lock
              </button>
            </Tooltip>
            {params.row.mac_pin ? <p style={{flex: '0 0 76.4px' , maxWidth: '76.4px' , textAlign: 'center'}}>{params.row.mac_pin}</p> :     <Tooltip
            placement="right-start"
            title={
              handleOStype(params) ? "Mac cannot be unlocked from here" : ""
            }
          >
            <button
              disabled={!isLocked || processingId !== "" || handleOStype(params)}
              style={{
                backgroundColor:  !isLocked ? "rgba(128, 128, 128, 0.3)" : "rgba(0, 128, 0, 0.3)",
                color: !isLocked ? "#999" : "#000",
                border: !isLocked ? "1px solid rgba(128, 128, 128, 1)" :  "1px solid rgba(0, 128, 0, 1)",
                padding: "8px 16px",
                borderRadius: "4px",
                cursor: "pointer",
                margin : 0,
                pointerEvents: isLocked ? "auto" : "none" 
              }}
              onClick={() => {
                setProcessingId(params.id);
                setTimeout(() => {
                  handleLockUnlockUser(params);
                }, 500);
              }}
            >
              Unlock
            </button>
          </Tooltip> }
       
          </>
          ) : (
            <span>No device attached</span>
          )}
        </div>
        )
      }
    },
    { field: "lock_count", headerName: "Device Lock Count", width: 150 },
    { field: "remove_user", headerName: "Remove User", width: 150,
    renderCell: (params: any) => (
      <div>
        <button
             style={{
              backgroundColor: "rgba(255, 0, 0, 0.3)" ,
              color: "#000",
              border: "1px solid rgba(255, 0, 0, 1)",
              padding: "8px 16px",
              borderRadius: "4px",
              cursor: "pointer",
            }}
            onClick={() => handleRemoveUser(params)}
            >
              Remove
            </button>
      </div>
    ),
    },
  ];

  // // searches and filters the array
  // const handleSearch = () => {
  //   const filteredUsers = users.filter(
  //     (user) =>
  //       user?.display_name !== undefined &&
  //       user?.display_name.toLowerCase().includes(search.toLowerCase())
  //   );
  //   setUsers(filteredUsers);
  // };

  // const handleClear = () => {
  //   setSearch("");
  //   setUsers(originalUsers);
  // };
  console.log(users , "users");
  
  return (
    <div className="pageWrapper">
      <NavBar />
      <Container className="mui-custom-container" >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "16px",
          }}
        ></div>
        <div className="innerWrapper" style={{ height: "calc(100vh - 64px)", width: "100%" }}>
          <h2>Devices</h2>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              {/* <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="50px"
              width="400px"
              margin="0 auto 30px"
            >
              <SearchBar
                label="Search"
                searchValue={search}
                onChange={setSearch}
                onClear={handleClear}
                onSearch={handleSearch}
              />
            </Box> */}
              <DataTable row={users} columns={columns} />
            </>
          )}
        </div>
      </Container>
    </div>
  );
};

export default Devices;
