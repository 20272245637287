export const DATA = {
  osType: {
    mac: "macos",
    windows: "windows",
  },
  dashFields: {
    percentage: "percentage",
    totalHours: "totalHours",
    lockedText: "lockedText",
    email: "email",
    emailSubject: "emailSubject",
    emailMsg: "emailMessage",
    activeCollab: "activeCollabe",
    scaleFusion: "scaleFusion",
  },
  endPoints: {
    activeCollab:
      "https://us-central1-timesheet-lock-poc.cloudfunctions.net/updateDatabase",
    scaleFusion:
      "https://us-central1-timesheet-lock-poc.cloudfunctions.net/fetchAndSaveDevicesData",
  },
  regex: {
    emailReg: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  },
};
