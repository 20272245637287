import React, { useEffect, useState, useMemo } from "react";
import { Outlet,useNavigate } from "react-router-dom";
import { User as FirebaseAuthUser } from "firebase/auth";
import firebase from "../firebaseConfig";
// import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import NavBar from "../components/NavBar";
import { Button, TextField, Grid, TextareaAutosize } from "@mui/material";
import axios from "axios";
import "./Dashboard.css";
import { DATA } from "../utils/constants/data";

interface DashboardProps {
  user: FirebaseAuthUser | null;
  // handleLogout: () => Promise<void>;
  children?: React.ReactNode; // Add the 'children' prop
}

interface FirestoreCounts {
  users: number;
  devices: number;
  whitelist: number;
  lockedUsers: number;
  unlockedUsers: number;
}

interface FirestoreSettings {
  percentage: number;
  totalHours: number;
  lockedText: string;
  emailMsg: string;
  email: string;
  emailSubject: string;
}

const Dashboard: React.FC<DashboardProps> = ({ user }) => {
  const { dashFields, endPoints, regex } = DATA;
  const [counts, setCounts] = useState<FirestoreCounts>({
    users: 0,
    devices: 0,
    whitelist: 0,
    lockedUsers: 0,
    unlockedUsers: 0,
  });
  const [loading, setLoading] = useState(true); // State to track loading status
  const [setting, setSetting] = useState<FirestoreSettings>({
    percentage: 0,
    totalHours: 0,
    lockedText: "",
    emailMsg: "",
    email: "",
    emailSubject: "",
  });
  const [percent, setPercent] = useState<number>(0);
  const [totalHours, setTotalHours] = useState<number>(0);
  const [lockedText, setLockedText] = useState<string>("");
  const [emailMsg, setEmailMsg] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [emailSubject, setEmailSubject] = useState<string>("");
  const [loaderType, setLoaderType] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [saveBtn, setSaveBtn] = useState(false);
  const [activeCollabBtn, setActiveCollabBtn] = useState(false);
  const [scaleFusionbBtn, setScaleFusionbBtn] = useState(false);

  useEffect(() => {
    if (setting) {
      const {
        percentage,
        totalHours,
        lockedText,
        email,
        emailMsg,
        emailSubject,
      } = setting;
      setPercent(percentage);
      setTotalHours(totalHours);
      setLockedText(lockedText);
      setEmail(email);
      setEmailMsg(emailMsg);
      setEmailSubject(emailSubject);
    }
  }, [setting]);

  const fetchData = async () => {
    try {
      const db = firebase.firestore();
      const usersSnapshot = await db.collection("users").get();

      const usersCollection = db.collection('users');
      const devicesSnapshot = await usersCollection.where('device_id', '!=', null).get();

      const usersCount = usersSnapshot.size;
      const devicesCount = devicesSnapshot.size;

      // Calculate locked/unlocked user count
      let lockedUsersCount = 0;
      let unlockedUsersCount = 0;
      let whiteListUsersCount = 0;
      usersSnapshot.forEach((userDoc) => {
        const userData = userDoc.data();
        if (userData.locked) {
          lockedUsersCount++;
        }
        if (!userData.locked && userData.device_id) {
          unlockedUsersCount++;
        }
      });
      usersSnapshot.forEach((userDoc) => {
        const userData = userDoc.data();
        if (userData.whitelist) {
          whiteListUsersCount++;
        }
      });

      setCounts({
        users: usersCount,
        devices: devicesCount,
        whitelist: whiteListUsersCount,
        lockedUsers: lockedUsersCount,
        unlockedUsers: unlockedUsersCount, 
      });
      setLoading(false); // Set loading to false after data is fetched
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchSettings = async () => {
    try {
      const db = firebase.firestore();
      const settings = db.collection("settings").doc("config");
      const settingGet = await settings.get();
      const settingData = settingGet.data();
      if (settingData) {
        const {
          percentage_required,
          total_weekly_hours,
          lock_device_message,
          email_message,
          from_email,
          email_subject,
        } = settingData;
        setSetting({
          percentage: percentage_required,
          totalHours: total_weekly_hours,
          lockedText: lock_device_message,
          emailMsg: email_message,
          email: from_email,
          emailSubject: email_subject,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdatePercentage = async () => {
    try {
      setSaveBtn(true);
      setLoaderType(dashFields.percentage);
      const db = firebase.firestore();
      await db.collection("settings").doc("config").update({
        percentage_required: percent,
      });
      setSaveBtn(false);
      setLoaderType("");
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateTotalHours = async () => {
    try {
      setSaveBtn(true);
      setLoaderType(dashFields.totalHours);
      const db = firebase.firestore();
      await db.collection("settings").doc("config").update({
        total_weekly_hours: totalHours,
      });
      setLoaderType("");
      setSaveBtn(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateLockedText = async () => {
    try {
      setSaveBtn(true);
      setLoaderType(dashFields.lockedText);
      const db = firebase.firestore();
      await db.collection("settings").doc("config").update({
        lock_device_message: lockedText,
      });
      setLoaderType("");
      setSaveBtn(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEmail = async () => {
    setSaveBtn(true);

    setError(null); // Reset the error state before login

    // Validate email format using regular expression

    if (!regex.emailReg.test(email)) {
      setError("Invalid email format");
      setSaveBtn(false);
      return;
    }

    try {
      setLoaderType(dashFields.email);
      const db = firebase.firestore();
      await db.collection("settings").doc("config").update({
        from_email: email,
      });
      setLoaderType("");
      setSaveBtn(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEmailSubject = async () => {
    try {
      setSaveBtn(true);

      setLoaderType(dashFields.emailSubject);
      const db = firebase.firestore();
      await db.collection("settings").doc("config").update({
        email_subject: emailSubject,
      });
      setLoaderType("");
      setSaveBtn(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEmailMsg = async () => {
    try {
      setSaveBtn(true);

      setLoaderType(dashFields.emailMsg);
      const db = firebase.firestore();
      await db.collection("settings").doc("config").update({
        email_message: emailMsg,
      });
      setLoaderType("");
      setSaveBtn(false);
    } catch (error) {
      console.log(error);
    }
  };

  const saveDisabler = useMemo(() => {
    if (!isNaN(percent) && percent <= 100) {
      return false;
    } else {
      return true;
    }
  }, [percent]);

  const hoursDisabler = useMemo(() => {
    if (!isNaN(totalHours) && totalHours <= 200) {
      return false;
    } else {
      return true;
    }
  }, [totalHours]);

  useEffect(() => {
    // Fetch data from Firestore and update the state
    fetchData();
    fetchSettings();
  }, []);

  const handleActiveCollab = () => {
    setActiveCollabBtn(true);
    axios
      .get(endPoints.activeCollab)
      .then(() => fetchData())
      .then(() => setActiveCollabBtn(false))
      .catch((err) => {
        console.log("err", err);
        setActiveCollabBtn(false);
      });
  };
  const handleScaleFusion = () => {
    setScaleFusionbBtn(true);
    axios
      .get(endPoints.scaleFusion)
      .then(() => fetchData())
      .then(() => setScaleFusionbBtn(false))
      .catch((err) => {
        console.log("err", err);
        setScaleFusionbBtn(false);
      });
  };
  

  const navigate = useNavigate();

  type FilterCriteria = 'all' | 'locked' | 'unlocked' | 'whitelisted'

  const redirectToFilteredDevicesPage = (filterCriteria: FilterCriteria) => {
    navigate(`/devices?filter=${filterCriteria}`);
  };

  const redirectToFilteredWhitelistPage = (filterCriteria: FilterCriteria) => {
    navigate(`/whitelist?filter=${filterCriteria}`);
  };
  
  return (
    <div>
      <NavBar />
      {/* Show loader while fetching data */}
      {loading ? (
        <div style={{ height: "calc(100vh - 64px)", width: "100%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </div>
        </div>
      ) : (
        <>
          <div className="secWrapper">
            <h1>Dashboard</h1>
            <Grid container className="contentWrapper">
              <Grid item xs={12} lg={5}>
                <Box className="itemWrapper">
                  <Grid container spacing={2}>
                    <Grid item xs={10} sm={5} md={6}>
                      <Box className="itemBox clickable" onClick={() => redirectToFilteredDevicesPage("all")}>
                        <p>Total Users: {counts.users}</p>
                      </Box>
                    </Grid>

                    <Grid item xs={10} sm={5} md={6}>
                      <Box className="itemBox clickable" onClick={() => redirectToFilteredDevicesPage("all")}>
                        <p>Total Devices: {counts.devices}</p>
                      </Box>
                    </Grid>

                    <Grid item xs={10} sm={5} md={6}>
                      <Box className="itemBox clickable" onClick={() => redirectToFilteredDevicesPage("locked")}>
                        <p>Locked Devices: {counts.lockedUsers}</p>
                      </Box>
                    </Grid>

                    <Grid item xs={10} sm={5} md={6}>
                      <Box className="itemBox clickable" onClick={() => redirectToFilteredDevicesPage("unlocked")}>
                        <p>Unlocked Devices: {counts.unlockedUsers}</p>
                      </Box>
                    </Grid>

                    <Grid item xs={10} sm={5} md={6}>
                      <Box className="itemBox clickable" onClick={() => redirectToFilteredWhitelistPage("whitelisted")}>
                        <p>Whitelist Devices: {counts.whitelist}</p>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Box className="configurationWrapper">
                  <h3>Configuration</h3>
                  <div className="configurationItems">
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Box className="itemBox sty1">
                          <label>Timesheet min %age required: </label>
                          <div className="contentWrap">
                            <TextField
                              className="ageField"
                              value={percent}
                              onChange={(e) => {
                                if (
                                  typeof parseInt(e.target.value) === "number"
                                ) {
                                  setPercent(parseInt(e.target.value));
                                }
                              }}
                              helperText={"Value must be between 0 and 100."}
                              type="number"
                            />
                            {loaderType !== dashFields.percentage && (
                              <Button
                                className="btnPrimary"
                                disabled={saveDisabler || saveBtn}
                                onClick={handleUpdatePercentage}
                              >
                                Save
                              </Button>
                            )}
                            {loaderType === dashFields.percentage && (
                              <CircularProgress
                                size={30}
                                style={{ marginLeft: "15px" }}
                              />
                            )}
                          </div>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box className="itemBox sty1">
                          <label>Text to display on locked device: </label>
                          <div className="contentWrap">
                            <TextareaAutosize
                              minRows={2}
                              className="ageField"
                              value={lockedText}
                              onChange={(e) => setLockedText(e.target.value)}
                            />
                            {loaderType !== dashFields.lockedText && (
                              <Button
                                className="btnPrimary"
                                disabled={!lockedText || saveBtn}
                                onClick={handleUpdateLockedText}
                              >
                                Save
                              </Button>
                            )}
                            {loaderType === dashFields.lockedText && (
                              <CircularProgress
                                size={30}
                                style={{ marginLeft: "15px" }}
                              />
                            )}
                          </div>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box className="itemBox sty1">
                          <label>Total weekly hours: </label>
                          <div className="contentWrap">
                            <TextField
                              className="ageField"
                              value={totalHours}
                              onChange={(e) => {
                                if (
                                  typeof parseInt(e.target.value) === "number"
                                ) {
                                  setTotalHours(parseInt(e.target.value));
                                }
                              }}
                              type="number"
                            />
                            {loaderType !== dashFields.totalHours && (
                              <Button
                                className="btnPrimary"
                                disabled={hoursDisabler || saveBtn}
                                onClick={handleUpdateTotalHours}
                              >
                                Save
                              </Button>
                            )}
                            {loaderType === dashFields.totalHours && (
                              <CircularProgress
                                size={30}
                                style={{ marginLeft: "15px" }}
                              />
                            )}
                          </div>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box className="itemBox sty1">
                          <label>Email: </label>
                          <div className="contentWrap">
                            <TextField
                              className="ageField"
                              value={email}
                              onChange={(e) => {
                                setEmail(e.target.value);
                                setError(null);
                              }}
                              type="text"
                            />

                            {loaderType !== dashFields.email && (
                              <Button
                                className="btnPrimary"
                                disabled={!email || saveBtn}
                                onClick={handleEmail}
                              >
                                Save
                              </Button>
                            )}
                            {error && (
                              <>
                                <br />
                                <div className="error-message">{error}</div>
                              </>
                            )}
                            {loaderType === dashFields.email && (
                              <CircularProgress
                                size={30}
                                style={{ marginLeft: "15px" }}
                              />
                            )}
                          </div>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box className="itemBox sty1">
                          <label>Email Subject: </label>
                          <div className="contentWrap">
                            <TextareaAutosize
                              className="ageField"
                              value={emailSubject}
                              minRows={5}
                              onChange={(e) => {
                                setEmailSubject(e.target.value);
                              }}
                            />
                            {loaderType !== dashFields.emailSubject && (
                              <Button
                                className="btnPrimary"
                                disabled={!emailSubject || saveBtn}
                                onClick={handleEmailSubject}
                              >
                                Save
                              </Button>
                            )}
                            {loaderType === dashFields.emailSubject && (
                              <CircularProgress
                                size={30}
                                style={{ marginLeft: "15px" }}
                              />
                            )}
                          </div>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box className="itemBox sty1">
                          <label>Email Message: </label>
                          <div className="contentWrap">
                            <TextareaAutosize
                              className="ageField"
                              minRows={5}
                              value={emailMsg}
                              onChange={(e) => {
                                setEmailMsg(e.target.value);
                              }}
                            />
                            {loaderType !== dashFields.emailMsg && (
                              <Button
                                className="btnPrimary"
                                disabled={!emailMsg || saveBtn}
                                onClick={handleEmailMsg}
                              >
                                Save
                              </Button>
                            )}
                            {loaderType === dashFields.emailMsg && (
                              <CircularProgress
                                size={30}
                                style={{ marginLeft: "15px" }}
                              />
                            )}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                    <Box className="itemBox sty1 syncPill">
                      {/* <label>Sync data from Activecollab</label> */}
                      <Button
                        disabled={activeCollabBtn}
                        className="btnPrimary"
                        onClick={handleActiveCollab}
                      >
                        Sync data from Active Collab
                      </Button>
                      <Button
                        className="btnPrimary"
                        disabled={scaleFusionbBtn}
                        onClick={handleScaleFusion}
                      >
                        Sync data from Scale Fusion
                      </Button>
                    </Box>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </div>
        </>
      )}

      {/* Outlet to render nested routes */}
      <Outlet />
    </div>
  );
};

export default Dashboard;
