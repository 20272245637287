import { createTheme } from "@mui/material/styles";
import { Theme } from "@mui/material/styles/createTheme";

const theme: Theme = createTheme({
  palette: {
    primary: {
      light: "#3a75b5",
      main: "#0C5793",
      dark: "#0C5793", // Set your primary color here
    },
    common: {
      white: "#ffffff",
    },
    // common2: {
    //   black: "#000000",
    // },
    // Add more customizations to the theme as needed
  },
  typography: {
    fontFamily: "'Roboto', sans-serif", // Set your desired default font family
    h6: {
      fontSize: "1.5rem",
      fontWeight: "bold",
    },
  },
  // Add other custom theme settings if needed
});

export default theme;
